export class UserTokens {
    accessToken: string;
    refreshToken: string;
    userId: string;
    corporationDetailId: string;
    corporationUserId: string;
    userIdentifier: string;
    name: string;
  
    constructor(json: string) {
        if (json == null) {return;}
  
        const obj = JSON.parse(json);
        
        this.accessToken = obj.accessToken;
        this.refreshToken = obj.refreshToken;
        this.userId = obj.userId;
        this.corporationDetailId = obj.corporationDetailId;
        this.corporationUserId = obj.corporationUserId;
        this.userIdentifier = obj.userIdentifier;
        this.name = obj.name;
    }
}