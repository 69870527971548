import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// Other external imports
import { Stripe, StripeElements, StripeCardElement, loadStripe, PaymentMethod } from '@stripe/stripe-js';

// Internal providers
import { ManageReservationProvider } from 'src/app/providers/manage-reservations-provider/manage-reservation.provider';

// Internal services
import { StripePaymentMethodService } from 'src/app/services/stripe-service/payment-methods/stripe-payment-method.service';

// Internal models
import { ReservationDto } from 'src/app/models/reservations/objects/reservation.dto';
import { CorporationDetailDto } from 'src/app/models/venue/public-corporation-detail.dto';
import { ReservationAdditionalGuestDto } from 'src/app/models/reservations/objects/reservation-additional-guest.dto';


@Injectable({
  providedIn: 'root'
})
export class StripeProvider {
  stripePublishableKey: string;

  // add payment method variables
  cardError: string | null = null;
  isProcessing: boolean = false;

  constructor(
    private manageReservationProvider: ManageReservationProvider,
    private stripePaymentMethodService: StripePaymentMethodService
  ) { }

  async createPaymentMethod(
    reservationDetails: ReservationDto | ReservationAdditionalGuestDto,
    stripeConnectAccountId: string,
    corporationDetailId: string,
    corporationUserId: string,
    stripe: Stripe,
    card: StripeCardElement
  ): Promise<{ error: string | null, newPaymentMethodId: string | null }> {
    try {
        const setupIntentBody = {
          stripeCustomerId:reservationDetails.associatedStripeCustomerId,
          stripeConnectAccountId: stripeConnectAccountId
        }
        // Call your backend to create a SetupIntent
        const response: any = await this.stripePaymentMethodService.createSetupIntent(setupIntentBody);
  
        const clientSecret = response.clientSecret;
  
        const result = await stripe.confirmCardSetup(clientSecret, {
          payment_method: {
            card,
            //Billing details are optional, but recommended for future use
            billing_details: {
              name: reservationDetails.fullName,
              email: reservationDetails.email,
              phone: reservationDetails.phoneNumber
              //TODO: Add address
              // address: {
              //   city: reservationDetails.city,
              //   country: reservationDetails.country,
              //   line1: reservationDetails.address,
              //   line2: reservationDetails.address2,
              //   postal_code: reservationDetails.postalCode,
              //   state: reservationDetails.state,
              // }
            },
            metadata: {
              sourceReservationId: reservationDetails.reservationId,
              guestProfileId: reservationDetails.guestProfileId,
              corporationDetailId: corporationDetailId
            }
          },
        });

        if (result.error) {
          // Display error.message in your UI.
          this.cardError = result.error.message || 'An unexpected error occurred.';
          this.isProcessing = false;
          return { error: this.cardError, newPaymentMethodId: null };
        } else {
          // The setup has succeeded. You can now inform your backend to attach the payment method.
          const paymentMethodId = result.setupIntent.payment_method;
  
          const attachPaymentMethodBody = {
            paymentMethodId,
            stripeCustomerId: reservationDetails.associatedStripeCustomerId,
            guestProfileId: reservationDetails.guestProfileId,
            corporationUserId: corporationUserId
          }
          console.log(attachPaymentMethodBody);
  
          const attachedResponse = await this.stripePaymentMethodService.attachPaymentMethod(attachPaymentMethodBody);
  
          if (attachedResponse.success) {
            this.isProcessing = false;
            return { error: null, newPaymentMethodId: attachedResponse.paymentMethod.guestPaymentMethodId };
          } else {
            this.cardError = 'An unexpected error occurred.';
            this.isProcessing = false;
            return { error: this.cardError, newPaymentMethodId: null };
          }
        }
      } catch (error: any) {
        console.error('Error adding payment method:', error);
        this.cardError = error?.message || 'An unexpected error occurred.';
        this.isProcessing = false;
        return { error: this.cardError, newPaymentMethodId: null };
      }
    }
}
