import { Injectable } from '@angular/core';
import { BaseHttpService } from '../base-http-service.service';
import { AuthInterceptorService } from 'src/app/interceptors/auth.interceptor';

@Injectable({
  providedIn: 'root'
})
export class ReservationsService extends BaseHttpService {
  constructor(authInterceptor: AuthInterceptorService) {
    super(authInterceptor);
  }

  // ! POST Requests

  // ! GET Requests
  async getExistingPurchase(pendingPurchaseId: string, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/SpotliteReservations/PendingPurchaseDetails?pid=${pendingPurchaseId}`;
    return this.get<any>(endpoint, retry);
  }

  async getReservationDetails(reservationId: string, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/SpotliteReservations/ReservationById?rid=${reservationId}`;
    return this.get<any>(endpoint, retry);
  }

  async getPendingReservationDetails(pendingPurchaseId: string, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/SpotliteReservations/PendingReservation?pid=${pendingPurchaseId}`;
    return this.get<any>(endpoint, retry);
  }

  async getReservationCosts(reservationId: string, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/SpotliteReservations/ReservationCost?rid=${reservationId}`;
    return this.get<any>(endpoint, retry);
  }

  async getPendingReservationCosts(pendingPurchaseId: string, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/SpotliteReservations/PendingReservationCost?pid=${pendingPurchaseId}`;
    return this.get<any>(endpoint, retry);
  }

  async getCorporationDetails(corporationDetailId: string, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/SpotliteReservations/PublicCorporationDetails?cid=${corporationDetailId}`;
    return this.get<any>(endpoint, retry);
  }

  async getAllReservationGuests(reservationId: string, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/SpotliteReservations/AllReservationGuests?rid=${reservationId}`;
    return this.get<any>(endpoint, retry);
  }

  // ! PUT Requests
  async updateReservationEmail(body: object, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/SpotliteReservations/UpdateReservationEmail`;
    return this.put<any>(endpoint, body, retry);
  }

  async inviteAdditionalGuests(body: object, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/SpotliteReservations/InviteAdditionalGuests`;
    return this.put<any>(endpoint, body, retry);
  }
}