import { Injectable } from '@angular/core';
import { BaseHttpService } from '../../base-http-service.service';
import { AuthInterceptorService } from 'src/app/interceptors/auth.interceptor';

@Injectable({
  providedIn: 'root'
})
export class StripePaymentMethodService extends BaseHttpService {
  constructor(authInterceptor: AuthInterceptorService) {
    super(authInterceptor);
  }

  // ! POST Requests
  async createSetupIntent(body: object, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/Stripe/PaymentMethods/CreateSetupIntent`;
    return this.post<any>(endpoint, body, retry);
  }

  async attachPaymentMethod(body: object, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/Stripe/PaymentMethods/AttachPaymentMethod`;
    return this.post<any>(endpoint, body, retry);
  }

  // ! GET Requests
  async getPaymentMethods(cid: string, caid: string, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/Stripe/PaymentMethods/SavedPaymentMethods?cid=${cid}&caid=${caid}`;
    return this.get<any>(endpoint, retry);
  }

  // ! PUT Requests
  async removePaymentMethod(body: object, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/Stripe/PaymentMethods/Remove`;
    return this.put<any>(endpoint, body, retry);
  }

  async setDefaultPaymentMethod(body: object, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/Stripe/PaymentMethods/SetDefault`;
    return this.put<any>(endpoint, body, retry);
  }
}