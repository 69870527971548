import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'confirm-reservation',
    loadChildren: () => import('./confirm-reservation/confirm-reservation.module').then( m => m.ConfirmReservationPageModule)
  },
  {
    path: 'venue',
    loadChildren: () => import('./venue/venue.module').then( m => m.VenuePageModule)
  },
  {
    path: 'manage-reservation',
    loadChildren: () => import('./manage-reservation/manage-reservation.module').then( m => m.ManageReservationPageModule)
  },
  {
    path: 'accept-invite',
    loadChildren: () => import('./accept-invite/accept-invite.module').then( m => m.AcceptInvitePageModule)
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('./terms-and-conditions/terms-and-conditions.module').then( m => m.TermsAndConditionsPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'reservation-submission/success',
    loadChildren: () => import('./reservation-submission/success/success.module').then( m => m.SuccessPageModule)
  },
  {
    path: 'end-user-terms',
    loadChildren: () => import('./end-user-terms/end-user-terms.module').then( m => m.EndUserTermsPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
