export const environment = {
  production: true,
  stage: false,
  appVersion: '0.0.1',
  appDistVersion: '0.0.1',
  name: 'spotlite-prod',
  
  baseUrl: 'https://web-eagleeye-prod-be.azurewebsites.net',
  venueUrl: 'https://eagleeyeapp.ai',
  publicUrl: 'https://spotlite.app',
  proxyUrl: 'https://web-eagleeye-proxy-prod-be.azurewebsites.net',
  
  sentryName: 'spotlite-prod',
  sentryDsn: 'https://48f8e73abf66e92a9c9b2af9cde7259c@o4508129477394432.ingest.us.sentry.io/4508218560217088',
  sentryTracesSampleRate: 0.3,
  sentryReplaysSessionSampleRate: 0.1,
  sentryReplaysOnErrorSampleRate: 1.0,
  sentryProfilesSampleRate: 0.1,
  sentryDebug: false,
};
