import { Injectable } from '@angular/core';

// Internal services
import { BaseHttpService } from '../base-http-service.service';
import { AuthInterceptorService } from 'src/app/interceptors/auth.interceptor';

// Internal models


@Injectable({
  providedIn: 'root'
})
export class GuestInvitationService extends BaseHttpService {
  constructor(authInterceptor: AuthInterceptorService) {
    super(authInterceptor);
  }

  // ! POST Requests
  
  
  // ! GET Requests
  async getInvitationDetails(inviteId: string, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/SpotliteInvitation/InvitationDetails?id=${inviteId}`;
    return this.get<any>(endpoint, retry);
  }

  // ! PUT Requests
  async updateGuestPhoneNumber(body: any, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/SpotliteInvitation/GuestPhoneNumber`;
    return this.put<any>(endpoint, body, retry);
  }

  async acceptInvite(body: any, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/SpotliteInvitation/AcceptInvite`;
    return this.put<any>(endpoint, body, retry);
  }
  
}