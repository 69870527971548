import { Injectable } from '@angular/core';

// Internal services
import { BaseHttpService } from '../base-http-service.service';
import { AuthInterceptorService } from 'src/app/interceptors/auth.interceptor';

// Internal models


@Injectable({
  providedIn: 'root'
})
export class SpotliteAuthService extends BaseHttpService {
  constructor(authInterceptor: AuthInterceptorService) {
    super(authInterceptor);
  }

  // ! POST Requests
  async sendVerificationCode(body: object, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/SpotliteAuth/SendVerificationCode`;
    return this.post<any>(endpoint, body, retry);
  }

  async checkVerificationCode(body: object, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/SpotliteAuth/CheckVerificationCode`;
    return this.post<any>(endpoint, body, retry);
  }
  
  
  // ! GET Requests
  

  // ! PUT Requests
  
  
}