import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// Internal models
import { ReservationCostDto } from 'src/app/models/reservations/objects/reservation-cost.dto';
import { ReservationDto } from 'src/app/models/reservations/objects/reservation.dto';
import { CorporationDetailDto } from 'src/app/models/venue/public-corporation-detail.dto';
import { CorporationMembershipsDto } from 'src/app/models/memberships/objects/corporation-membership.dto';
import { MembershipSubscriptionsDto } from 'src/app/models/memberships/objects/membership-members.dto';
import { GuestPaymentMethodDto } from 'src/app/models/reservations/objects/guest-payment-method.dto';
import { ReservationAdditionalGuestDto } from 'src/app/models/reservations/objects/reservation-additional-guest.dto';
import { ActivateGuestMembershipStep } from 'src/app/models/memberships/objects/activate-guest-membership-step.object';

@Injectable({
  providedIn: 'root'
})
export class ManageReservationProvider {
  stripePublishableKey: string;

  reservationDetails: ReservationDto;
  reservationCosts: ReservationCostDto;
  corporationDetails: CorporationDetailDto;
  venueMemberships: CorporationMembershipsDto[];
  guestMemberships: MembershipSubscriptionsDto[];
  manageReservationDataLoadedObservable = new Subject<boolean>();

  // payment methods variables
  showAddPaymentMethodModal = false;
  showAddPaymentMethodObservable = new Subject<boolean>();

  showRemovePaymentMethodModal = false;
  selectedPaymentMethodForRemoval: GuestPaymentMethodDto;
  showRemovePaymentMethodObservable = new Subject<boolean>();

  // payment details variables
  showPayRemainingBalanceModal = false;
  showPayRemainingBalanceObservable = new Subject<boolean>();

  // membership status variables
  showUpgradeMembershipStatusModal = false;
  showUpgradeMembershipStatusObservable = new Subject<boolean>();
  venueMembershipsUpdatedObservable = new Subject<boolean>();

  // guest information variables
  showAddGuestModal = false;
  showAddGuestObservable = new Subject<boolean>();

  showInviteGuestModal = false;
  inviteGuestReservationData: ReservationDto;
  showInviteGuestObservable = new Subject<boolean>();

  showActivateGuestMembershipModal = false;
  showActivateGuestMembershipObservable = new Subject<boolean>();
  activateGuestMembershipButtonDisabled = false;
  activateGuestMembershipButtonDisabledObservable = new Subject<boolean>();
  
  showRemoveGuestModal = false;
  removeGuestData: ReservationAdditionalGuestDto;
  showRemoveGuestObservable = new Subject<boolean>();

  constructor() { }

  emitDataLoaded(
    stripePublishableKey: string,
    reservationDetails: ReservationDto,
    reservationCosts: ReservationCostDto,
    corporationDetails: CorporationDetailDto,
    venueMemberships: CorporationMembershipsDto[],
    guestMemberships: MembershipSubscriptionsDto[],
    dataLoaded: boolean
  ) {
    this.stripePublishableKey = stripePublishableKey;
    this.reservationDetails = reservationDetails;
    this.reservationCosts = reservationCosts;
    this.corporationDetails = corporationDetails;
    this.venueMemberships = venueMemberships;
    this.guestMemberships = guestMemberships;
    this.manageReservationDataLoadedObservable.next(dataLoaded);
  }

  async getstripePublishableKey() {
    return this.stripePublishableKey;
  }

  async getReservationDetails() {
    return this.reservationDetails;
  }

  async getReservationCosts() {
    return this.reservationCosts;
  }

  async getCorporationDetails() {
    return this.corporationDetails;
  }

  async getAllDataLoaded() {
    const dataLoaded = {
      stripePublishableKey: this.stripePublishableKey,
      reservationDetails: this.reservationDetails,
      reservationCosts: this.reservationCosts,
      corporationDetails: this.corporationDetails,
      venueMemberships: this.venueMemberships,
      guestMemberships: this.guestMemberships
    };
    return dataLoaded;
  }


  // payment methods functions
  emitShowAddPaymentMethod(show: boolean) {
    this.showAddPaymentMethodModal = show;
    this.showAddPaymentMethodObservable.next(show);
  }

  emitShowRemovePaymentMethod(show: boolean, selectedPaymentMethodForRemoval: GuestPaymentMethodDto) {
    this.showRemovePaymentMethodModal = show;
    this.selectedPaymentMethodForRemoval = selectedPaymentMethodForRemoval;
    this.showRemovePaymentMethodObservable.next(show);
  }

  async getSelectedPaymentMethodForRemoval() {
    return this.selectedPaymentMethodForRemoval;
  }


  // payment details functions
  emitShowPayRemainingBalance(show: boolean) {
    this.showPayRemainingBalanceModal = show;
    this.showPayRemainingBalanceObservable.next(show);
  }

  
  // membership status functions
  emitShowUpgradeMembershipStatus(show: boolean) {
    this.showUpgradeMembershipStatusModal = show;
    this.showUpgradeMembershipStatusObservable.next(show);
  }

  async venueHasMinimumRequirement(): Promise<boolean> {
    return this.venueMemberships.some(membership => membership.isMinimumRequirement);
  }

  async guestMeetsMinimumRequirement(): Promise<boolean> {
    const guestMembership = this.guestMemberships
      .find(membership => membership.corporationMembershipId === this.venueMemberships
        .find(venueMembership => (venueMembership.isMinimumRequirement || venueMembership.supersedesMinimumRequirement) && venueMembership.isActive)?.corporationMembershipId);
    return guestMembership ? guestMembership.isActive : false;
  }

  async getVenueMemberships(): Promise<CorporationMembershipsDto[]> {
    return this.venueMemberships;
  }

  async getGuestMemberships(): Promise<MembershipSubscriptionsDto[]> {
    return this.guestMemberships;
  }

  emitVenueMembershipsUpdated(updatedMemberships: CorporationMembershipsDto[]) {
    this.venueMemberships = updatedMemberships;
    this.venueMembershipsUpdatedObservable.next(true);
  }

  async getUpdatedVenueMemberships(): Promise<CorporationMembershipsDto[]> {
    return this.venueMemberships;
  }


  // guest information functions
  emitShowAddGuest(show: boolean) {
    this.showAddGuestModal = show;
    this.showAddGuestObservable.next(show);
  }

  emitShowInviteGuest(show: boolean, inviteGuestReservationData: ReservationDto) {
    this.showInviteGuestModal = show;
    this.inviteGuestReservationData = inviteGuestReservationData;
    this.showInviteGuestObservable.next(show);
  }

  async getInviteGuestReservationData(): Promise<ReservationDto> {
    return this.inviteGuestReservationData;
  }

  emitShowActivateGuestMembership(show: boolean) {
    this.showActivateGuestMembershipModal = show;
    this.showActivateGuestMembershipObservable.next(show);
  }

  emitActivateGuestMembershipButtonDisabled(disabled: boolean) {
    this.activateGuestMembershipButtonDisabled = disabled;
    this.activateGuestMembershipButtonDisabledObservable.next(disabled);
  }

  async getActivateGuestMembershipButtonDisabled(): Promise<boolean> {
    return this.activateGuestMembershipButtonDisabled;
  }

  async closeAndResetActivateGuestMembership() {
    this.showActivateGuestMembershipModal = false;
    this.showActivateGuestMembershipObservable.next(false);
    this.activateGuestMembershipButtonDisabled = false;
    this.activateGuestMembershipButtonDisabledObservable.next(false);
  }

  emitShowRemoveGuest(show: boolean, removeGuestData: ReservationAdditionalGuestDto) {
    this.showRemoveGuestModal = show;
    this.removeGuestData = removeGuestData;
    this.showRemoveGuestObservable.next(show);
  }

  async getRemoveGuestData(): Promise<ReservationAdditionalGuestDto> {
    return this.removeGuestData;
  }
}
