import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

// Internal models
import { AcceptInviteStep } from 'src/app/models/accept-invite/objects/accept-invite-step.object';
import { InvitationDetailsResponse } from 'src/app/models/accept-invite/responses/invitation-details.response';

@Injectable({
  providedIn: 'root'
})
export class AcceptInviteProvider {
  // stripe publishable key
  stripePublishableKey: string;

  // stepper
  acceptInviteStep = 1;
  acceptInviteStepObservable = new Subject<number>();
  acceptInviteProceedObservable = new Subject<boolean>();
  steps: AcceptInviteStep[] = [
    { step: 0, iconName: '00', title: 'Invitation Accepted!', isActive: false },
    { step: 1, iconName: '01', title: 'Input Phone Number', isActive: true },
    { step: 2, iconName: '02', title: 'Verify Phone Number', isActive: false },
    { step: 3, iconName: '03', title: 'Review & Accept Invite', isActive: false }
  ]

  // invitation details
  invitationDetails: InvitationDetailsResponse;

  // upgrade membership
  showUpgradeMembershipModal: boolean = false;
  showUpgradeMembershipObservable = new Subject<boolean>();

  constructor() {}

  // #region Stripe publishable key functions
  async setStripePublishableKey(stripePublishableKey: string) {
    this.stripePublishableKey = stripePublishableKey;
  }
  async getStripePublishableKey(): Promise<string> {
    return this.stripePublishableKey;
  }
  // #endregion Stripe publishable key functions

  
  // #region Stepper functions
  async emitAcceptInviteStep(nextStep: number) {
    const isLastStep = await this.getAcceptInviteIsLastStep();
    if (isLastStep) {
      await this.emitAcceptInviteProceed(false);
      return;
    }

    this.acceptInviteStep = nextStep;
    
    const existingActive = this.steps.find(step => step.isActive);
    existingActive.isActive = false;

    // Set the new active step if nextStep is not the first step
    if (nextStep !== 0) {
      this.steps[nextStep - 1].isActive = true;
    } else {
      this.steps[0].isActive = true;
    }
    
    this.acceptInviteStepObservable.next(nextStep);
    this.emitAcceptInviteProceed(true);
  }

  async getAcceptInviteStep(): Promise<number> {
    return this.acceptInviteStep;
  }

  async getAcceptInviteSteps(): Promise<AcceptInviteStep[]> {
    return this.steps;
  }

  async getAcceptInviteContinueButtonText(): Promise<string> {
    const nextStep = this.acceptInviteStep;
    
    if (nextStep >= this.steps.length) {
      return 'Accept Invite';
    }

    const nextStepTitle = this.steps[nextStep].title;
    
    return `Continue to ${nextStepTitle}`;
  }

  async getAcceptInviteIsLastStep(): Promise<boolean> {
    return this.acceptInviteStep >= this.steps.length;
  }

  async emitAcceptInviteProceed(proceed: boolean) {
    this.acceptInviteProceedObservable.next(proceed);
  }
  // #endregion Stepper functions


  // #region Invitation details functions
  async setInvitationDetails(invitationDetails: InvitationDetailsResponse) {
    this.invitationDetails = invitationDetails;
  }

  async getInvitationDetails(): Promise<InvitationDetailsResponse> {
    return this.invitationDetails;
  }

  async updateGuestPhoneNumber(phoneNumber: string) {
    this.invitationDetails.additionalGuest.phoneNumber = phoneNumber;
  }
  // #endregion Invitation details functions

  // #region Upgrade membership functions
  async emitShowUpgradeMembership(show: boolean) {
    this.showUpgradeMembershipModal = show;
    this.showUpgradeMembershipObservable.next(show);
  }
  // #endregion Upgrade membership functions
}
