import { Injectable } from '@angular/core';
import { CapacitorHttp, HttpHeaders, HttpResponse } from '@capacitor/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { RefreshedSessionTokens } from '../../models/auth/refreshed-session-tokens.model';

// Internal models
import { UserTokens } from '../../models/auth/user-tokens.model';

// Internal environment
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseUrl = environment.proxyUrl;
  private userTokens = new UserTokens(null);
  private accessTokenKey = 'access_token';
  private refreshTokenKey = 'refresh_token';

  // BehaviorSubject to hold the authentication state
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(this.hasToken());

  isAuthenticated$ = this.isAuthenticatedSubject.asObservable();

  constructor() {

  }

  async setUserTokens(token: UserTokens) {
    this.userTokens = token;
    localStorage.setItem('uid', token.userId);
  }

  async setRefreshUserTokens(token: RefreshedSessionTokens) {
    this.userTokens.userId = token.userId;
    this.userTokens.corporationDetailId = token.corporationDetailId;
    this.userTokens.corporationUserId = token.corporationUserId;
    this.userTokens.userIdentifier = token.userIdentifier;
    this.userTokens.name = token.name;
  }

  async getUserId(): Promise<string | null> {
    return localStorage.getItem('uid');
  }

  async getCorporationDetailId(): Promise<string | null> {
    return this.userTokens.corporationDetailId;
  }

  async getCorporationUserId(): Promise<string | null> {
    return this.userTokens.corporationUserId;
  }

  /**
   * Retrieves the Access Token from localStorage.
   */
  getAccessToken(): string | null {
    return localStorage.getItem(this.accessTokenKey);
  }

  /**
   * Retrieves the Refresh Token from localStorage.
   */
  getRefreshToken(): string | null {
    return localStorage.getItem(this.refreshTokenKey);
  }

  /**
   * Stores the Access Token and Refresh Token in localStorage.
   */
  setTokens(accessToken: string, refreshToken: string): void {
    localStorage.setItem(this.accessTokenKey, accessToken);
    localStorage.setItem(this.refreshTokenKey, refreshToken);
  }

  setGuestToken(guestToken: string): void {
    localStorage.setItem(this.accessTokenKey, guestToken);
  }

  /**
   * Clears the tokens from localStorage.
   */
  clearTokens(): void {
    localStorage.removeItem(this.accessTokenKey);
    localStorage.removeItem(this.refreshTokenKey);
  }

  /**
   * Constructs the Authorization header value.
   */
  authorizationHeader(): string | null {
    const token = this.getAccessToken();
    return token ? `Bearer ${token}` : null;
  }

  /**
   * Checks if the Access Token exists in localStorage.
   */
  private hasToken(): boolean {
    return !!this.getAccessToken();
  }

  /**
   * Decodes a JWT token and returns its payload.
   */
  private decodeToken(token: string): any {
    try {
      const payload = token.split('.')[1];
      const decoded = atob(payload);
      return JSON.parse(decoded);
    } catch (e) {
      console.error('Failed to decode token:', e);
      return null;
    }
  }

  /**
   * Checks if the Access Token is expired.
   */
  isAccessTokenExpired(): boolean {
    const token = this.getAccessToken();
    if (!token) return true;

    const payload = this.decodeToken(token);
    if (!payload || !payload.exp) return true;

    const expiry = payload.exp * 1000; // Convert seconds to milliseconds
    return Date.now() > expiry;
  }


  /**
   * Initializes authentication by checking tokens and refreshing if necessary.
   */
  async initializeAuth(): Promise<void> {
    const accessToken = this.getAccessToken();
    const refreshToken = this.getRefreshToken();

    if (accessToken) {
      if (!this.isAccessTokenExpired()) {
        // Access Token is valid
        // Optionally, set user state or perform additional actions
        return;
      } else if (refreshToken) {
        // Access Token expired, attempt to refresh
        try {
          const refreshResponse = await this.refreshToken();
          if (refreshResponse.accessToken && refreshResponse.refreshToken) {
            // Token refreshed successfully
            return;
          } else {
            // Refresh failed, clear tokens
            this.clearTokens();
          }
        } catch (error) {
          console.error('Token refresh failed:', error);
          this.clearTokens();
        }
      } else {
        // No Refresh Token, clear tokens
        this.clearTokens();
      }
    }
    // If no valid tokens, ensure tokens are cleared
    this.clearTokens();
  }

  async logout(): Promise<any> {
    const url = `${this.baseUrl}/spotlite-proxy/Auth/logout`;
    const body = { refreshToken: this.getRefreshToken() };

    const options = {
      method: 'POST',
      url: url,
      headers: {
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      this.clearTokens();
      this.isAuthenticatedSubject.next(false);

      return response.data;
    } else {
      const error = new Error(response.data.message);
      (error as any).status = response.status; // Add status code to the error object
      (error as any).message = response.data.message; // Add message to the error object
      throw error;
    }
  }

  async refreshToken(): Promise<any> {
    const url = `${this.baseUrl}/spotlite-proxy/Auth/refresh-token`;
    const body = { 
      userId: await this.getUserId(),
      refreshToken: this.getRefreshToken() 
    };

    const options = {
      method: 'POST',
      url: url,
      headers: {
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      const responseData = response.data;

      if (responseData.tokens.accessToken && responseData.refreshToken) {
        this.setTokens(responseData.accessToken, responseData.refreshToken);
      }

      return responseData;
    } else {
      const error = new Error(response.data.message);
      (error as any).status = response.status; // Add status code to the error object
      (error as any).message = response.data.message; // Add message to the error object
      throw error;
    }
  }

  async getGuestAccessToken() {
    // Check if a token already exists and is not expired
    let token = localStorage.getItem(this.accessTokenKey);
    if (!token || await this.isGuestTokenExpired(token)) {
      const url = `${this.baseUrl}/spotlite-proxy/Auth/GuestToken`;
      const options = {
        method: 'POST',
        url: url,
        headers: {
          'Content-Type': 'application/json'
        },
        data: []
      };

      const response: HttpResponse = await CapacitorHttp.post(options);

      if (response.status === 200) {
        const responseData = response.data;

        if (responseData.accessToken) {
          this.setGuestToken(responseData.accessToken);
        }

        return responseData;
      } else {
        const error = new Error(response.data.message);
        (error as any).status = response.status; // Add status code to the error object
        (error as any).message = response.data.message; // Add message to the error object
        throw error;
      }
    }
    return token;
  }

  async isGuestTokenExpired(token: string): Promise<boolean> {
    const payload = JSON.parse(atob(token.split('.')[1]));
    const exp = payload.exp;
    // !The milliseconds need to always match the JwtGuestAccessTokenExpirationHours ENV variable
    //TODO: Need to fetch the JwtGuestAccessTokenExpirationHours from the API once per user session
    const currentTime = Math.floor(Date.now() / 1000);
    return exp < currentTime;
  }

}
