import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

// Internal providers
import { ManageReservationProvider } from '../manage-reservations-provider/manage-reservation.provider';

// Internal models
import { ReservationAdditionalGuestDto } from 'src/app/models/reservations/objects/reservation-additional-guest.dto';
import { ActivateGuestMembershipStep } from 'src/app/models/memberships/objects/activate-guest-membership-step.object';
import { ActivateGuestMembershipBasicInfoDto } from 'src/app/models/memberships/objects/activate-guest-membership-basic-info.dto';
import { MembershipSubscriptionsDto } from 'src/app/models/memberships/objects/membership-members.dto';
import { CorporationMembershipsDto } from 'src/app/models/memberships/objects/corporation-membership.dto';

@Injectable({
  providedIn: 'root'
})
export class MembershipsProvider {
  activateGuestData: ReservationAdditionalGuestDto | null = null;
  activateGuestMembershipStep = 1;
  activateGuestMembershipStepObservable = new Subject<number>();
  activateGuestMembershipProceedObservable = new Subject<boolean>();
  steps: ActivateGuestMembershipStep[] = [
    { step: 1, iconName: '01', title: 'Getting Started', description: 'Confirm that you would like to proceed', isActive: true },
    { step: 2, iconName: '02', title: 'Guest Information', description: 'Enter the guest\'s details', isActive: false },
    { step: 3, iconName: '03', title: 'Select Membership', description: 'Select the membership for the guest', isActive: false },
    { step: 4, iconName: '04', title: 'Payment Method', description: 'Enter the payment method for the guest\'s membership', isActive: false }
  ]

  // form variables
  guestInformation: ActivateGuestMembershipBasicInfoDto | null = null;

  selectedMembershipId: string | null = null;
  selectedMembership: CorporationMembershipsDto | null = null;
  membershipSelectedObservable = new Subject<boolean>();

  constructor() {}

  // Activate Guest Membership modal has been shown or closed and we need to hydrate the data respectively
  hydrateShowActivateGuestMembership(activateGuestData: ReservationAdditionalGuestDto | null) {
    this.activateGuestData = activateGuestData;
    this.activateGuestMembershipStep = 1;
    this.steps[0].isActive = true;
  }

  async getActivateGuestData(): Promise<ReservationAdditionalGuestDto> {
    return this.activateGuestData;
  }


  // #region Stepper functions
  async emitActivateGuestMembershipStep(nextStep: number) {
    const isLastStep = await this.getActivateGuestMembershipIsLastStep();
    if (isLastStep) {
      await this.emitActivateGuestMembershipProceed(false);
      return;
    }

    this.activateGuestMembershipStep = nextStep;
    
    const existingActive = this.steps.find(step => step.isActive);
    existingActive.isActive = false;

    // Set the new active step if nextStep is not the first step
    if (nextStep !== 1) {
      this.steps[nextStep - 1].isActive = true;
    } else {
      this.steps[0].isActive = true;
    }
    
    this.activateGuestMembershipStepObservable.next(nextStep);
    this.emitActivateGuestMembershipProceed(true);
  }

  async getActivateGuestMembershipStep(): Promise<number> {
    return this.activateGuestMembershipStep;
  }

  async getAllActivateGuestMembershipSteps(): Promise<ActivateGuestMembershipStep[]> {
    return this.steps;
  }

  async getActivateGuestMembershipContinueButtonText(): Promise<string> {
    const nextStep = this.activateGuestMembershipStep;
    
    if (nextStep >= this.steps.length) {
      return 'Activate Membership';
    }

    const nextStepTitle = this.steps[nextStep].title;
    
    return `Continue to ${nextStepTitle}`;
  }

  async getActivateGuestMembershipIsLastStep(): Promise<boolean> {
    return this.activateGuestMembershipStep >= this.steps.length;
  }

  async emitActivateGuestMembershipProceed(proceed: boolean) {
    this.activateGuestMembershipProceedObservable.next(proceed);
  }
  // #endregion Stepper functions

  // #region Guest Information functions
  hydrateGuestInformation(guestInformation: ActivateGuestMembershipBasicInfoDto) {
    this.guestInformation = guestInformation;
  }

  async getGuestInformation(): Promise<ActivateGuestMembershipBasicInfoDto> {
    return this.guestInformation;
  }
  // #endregion Guest Information functions

  // #region Select Membership functions
  async guestMeetsMinimumRequirement(
    memberships: MembershipSubscriptionsDto[] | MembershipSubscriptionsDto,
    venueMemberships: CorporationMembershipsDto[] | CorporationMembershipsDto
  ): Promise<boolean> {
    // Normalize inputs to arrays
    const membershipsArray = Array.isArray(memberships) ? memberships : [memberships];
    const venueMembershipsArray = Array.isArray(venueMemberships) ? venueMemberships : [venueMemberships];
  
    const guestMembership = membershipsArray.find(membership =>
      venueMembershipsArray.some(venueMembership =>
        (venueMembership.isMinimumRequirement || venueMembership.supersedesMinimumRequirement) &&
        venueMembership.isActive &&
        membership.corporationMembershipId === venueMembership.corporationMembershipId
      )
    );
  
    return guestMembership ? guestMembership.isActive : false;
  }

  selectMembership(membership: CorporationMembershipsDto | null) {
    this.selectedMembershipId = membership?.corporationMembershipId || null;
    this.selectedMembership = membership || null;
    this.membershipSelectedObservable.next(true);
  }

  async getSelectedMembershipId(): Promise<string | null> {
    return this.selectedMembershipId;
  }
  // #endregion Select Membership functions

  // #region reset Guest Membership variables to default
  async closeAndResetGuestMembershipVariables() {
    this.activateGuestData = null;

    this.activateGuestMembershipStep = 1;
    this.activateGuestMembershipStepObservable.next(1);
    this.activateGuestMembershipProceedObservable.next(false);
    
    this.steps = [
      { step: 1, iconName: '01', title: 'Getting Started', description: 'Confirm that you would like to proceed', isActive: true },
      { step: 2, iconName: '02', title: 'Guest Information', description: 'Enter the guest\'s details', isActive: false },
      { step: 3, iconName: '03', title: 'Select Membership', description: 'Select the membership for the guest', isActive: false },
      { step: 4, iconName: '04', title: 'Payment Method', description: 'Enter the payment method for the guest\'s membership', isActive: false }
    ]

    // form variables
    this.guestInformation = null;

    this.selectedMembershipId = null;
    this.selectedMembership = null;
    this.membershipSelectedObservable.next(false);
  }
  // #endregion reset Guest Membership variables to default
}
