import { Injectable } from '@angular/core';

// Internal services
import { BaseHttpService } from '../base-http-service.service';
import { AuthInterceptorService } from 'src/app/interceptors/auth.interceptor';

// Internal models
import { MembershipSubscriptionsDto } from 'src/app/models/memberships/objects/membership-members.dto';

@Injectable({
  providedIn: 'root'
})
export class MembershipService extends BaseHttpService {
  constructor(authInterceptor: AuthInterceptorService) {
    super(authInterceptor);
  }

  // ! POST Requests
  async upsertMembershipSubscription(body: any, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/SpotliteReservations/UpsertMembershipSubscription`;
    return this.post<any>(endpoint, body, retry);
  }

  async upsertAdditionalGuestMembershipSubscription(body: any, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/SpotliteReservations/UpsertAdditionalGuestMembershipSubscription`;
    return this.post<any>(endpoint, body, retry);
  }

  async activateGuestMembership(body: any, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/SpotliteReservations/ActivateGuestMembershipSubscription`;
    return this.post<any>(endpoint, body, retry);
  }
  
  // ! GET Requests
  async getVenueMemberships(corporationDetailId: string, retry = true): Promise<any | null> {
    const endpoint = `/spotlite-proxy/SpotliteReservations/CorporationMemberships?cid=${corporationDetailId}`;
    return this.get<any>(endpoint, retry);
  }

  async getGuestMemberships(guestProfileId: string, corporationUserId: string, retry = true): Promise<MembershipSubscriptionsDto[] | null> {
    const endpoint = `/spotlite-proxy/SpotliteReservations/GuestMemberships?gpid=${guestProfileId}&cuid=${corporationUserId}`;
    return this.get<MembershipSubscriptionsDto[]>(endpoint, retry);
  }

  // ! PUT Requests
  
}